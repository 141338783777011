import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import * as emailjs from "emailjs-com";

export default function ContactUsForm() {

    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting },
    } = useForm();

    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const onSubmit = handleSubmit(async ({name, email, comment}) => {
        if (!recaptchaToken) {
            toast.error("Пожалуйста, подтвердите, что вы не робот.");
            return;
        }

        console.log('Submitting form...');
        try {
            const result = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_CONTACTUS_TEMPLATE_ID,
                { name, email, comment },
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            toast.success("Ваше повідомлення успішно надіслано");
            console.log("Email sent successfully:", result.text);
        } catch (error) {
            console.error("Error sending email:", error.text);
            toast.error("Ошибка при отправке сообщения.");
        }
        console.log('Submission complete');
    });

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    React.useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({ name: '', email:'', comment:'' });
        }
    }, [formState, reset]);

    return (
        <>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">Ім’я</div>
                {errors?.name?.type === "required" && (
                    <p className="form-review-error"> Вкажіть, будь ласка, своє ім'я </p>
                )}
                {errors?.name?.type === "maxLength" && (
                    <p className="form-review-error">
                        Занадто багато символів
                    </p>
                )}
                {errors?.name?.type === "pattern" && (
                    <p className="form-review-error">Не валiднi данi</p>
                )}
                <input
                    type="text"
                    {...register("name", {
                        required: true,
                        maxLength: 28,
                        pattern: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'’ʼ\s-]+$/
                    })}
                    placeholder="Введіть своє ім’я"
                    className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
            </div>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">Пошта</div>
                {errors?.email?.type === "required" && (
                    <p className="form-review-error"> Поле email повинно бути заповнене </p>
                )}
                {errors?.email?.type === "maxLength" && (
                    <p className="form-review-error">
                        Занадто багато символів
                    </p>
                )}
                {errors?.email?.type === "pattern" && (
                    <p className="form-review-error">Не валiдна email адреса</p>
                )}
                <input
                    type="email"
                    {...register("email", {
                        required: true,
                        maxLength: 64,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                    placeholder="example@gmail.com"
                    className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
            </div>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">Коментар</div>
                {errors?.comment?.type === "required" && (
                    <p className="form-review-error"> Напишiть коментар </p>
                )}
                {errors?.comment?.type === "maxLength" && (
                    <p className="form-review-error">
                        Занадто багато символів. Обмеження в
                    </p>
                )}
                {errors?.comment?.type === "pattern" && (
                    <p className="form-review-error">Не валiднi данi. </p>
                )}
                <textarea
                    {...register("comment", {
                        required: true,
                        maxLength: 1024,
                        pattern: /^[a-zA-Zа-яА-ЯёЁіІїЇєЄ' ,.!@?-]+$/u,
                    })}
                    type="text"
                    placeholder="Введіть свій коментар"
                    className="h-48 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#595353] "/>
            </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleRecaptchaChange}
            />
            <div className="flex items-center gap-2">
                <button className="button" onClick={onSubmit} disabled={isSubmitting}>
                    Напишіть мені
                </button>
                {isSubmitting && <img src='/img/spinner.svg' alt="Logo" className="h-6 w-6" />}
            </div>
        </>
    )
}