import React from 'react';
import './Loader.scss';

const Loader = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="loader-overlay">
            <div className="loader">

            </div>
            <p className="loader-text text-2xl font-bold text-base">Триває аналіз, не закривайте сторінку.</p>
        </div>
    );
};

export default Loader;