import React from "react";
import AnchorLink from "../../components/AnchorLink";
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div className="inside">
                <div
                    className="mb-14 flex items-center justify-between max-xl:mb-10 max-md:flex-col max-md:gap-8 max-md:pt-6 max-md:mb-8">
                    <div>
                        <Link to="/">
                            <img
                                src="/img/logo.svg"
                                alt=""
                                width="200px"
                                className="max-w-full max-xl:w-28 max-md:w-[200px]"
                            />
                        </Link>
                    </div>
                    <div
                        className="flex gap-8 items-start text-base leading-normal max-md:flex-col max-md:items-center max-md:gap-4">
                        <AnchorLink href="/#howtoworkservice-section">
                            <a
                                href=""
                                className="text-black hover:text-[#418f44] transition duration-300 ease-in"
                            >
                                Як працює сервіс
                            </a>
                        </AnchorLink>
                        <AnchorLink href="/privacy-policy#privacy">
                            <a
                                href=""
                                className="text-black hover:text-[#418f44] transition duration-300 ease-in"
                            >
                                Політика конфіденційності
                            </a>
                        </AnchorLink>
                    </div>
                    <div className="flex gap-8 items-start text-base leading-normal">
                        <a
                            href="https://www.instagram.com/dniprolab?igsh=MWU3NjB0Njd1eXB6Yg%3D%3D"
                            className="text-black hover:text-[#418f44] transition duration-300 ease-in"
                            style={{display:'flex', gap:'16px'}}
                        >
                            <img className="w-full h-full object-cover" src="/img/instagram.svg" style={{width:'24px'}}/>
                            Інстаграм
                        </a>
                    </div>
                </div>
                <div className="px-2.5 pt-4 pb-8 border-t border-[#f0f0f0]">
                    <div className="text-[#b8b5b5] text-sm leading-[150%]">
                        © 2024 Dniprolab та Якість Життя. Всі права захищені.
                    </div>
                </div>
            </div>
        </footer>
    );
}
