import React from "react";
import EducationRow from "../../../InterpretationResult/components/oneResearchResult/components/EducationRow";
import ExperienceRow from "../../../InterpretationResult/components/oneResearchResult/components/ExperienceRow";
import MakeAnAppointmentLink from "./components/MakeAnAppointmentLink";

export default function OneHealer({ oneHealer, specialist, onAppointmentClick }) {
    const { photo_url, years_experience, education, last_name, first_name, specialization } = oneHealer;

    return (
        <div className="p-8 pb-12 flex flex-col gap-6 border border-[#f0f1f0] justify-between rounded-lg text-left max-md:p-4">
            <div className="h-[516px] w-full max-xl:h-[684px] max-sm:max-h-[392px]">
                <img className="w-full h-full object-cover rounded-sm" src={photo_url} alt={`${first_name} ${last_name}`} />
            </div>
            <div className="flex flex-col gap-1 max-md:gap-2">
                {specialist && (
                    <div className="flex flex-wrap gap-2 items-center">
                        <div className="px-4 py-2 bg-[#e3eee3] rounded-[28px] justify-center items-center gap-2.5 inline-flex">
                            <div className="text-[#06011e] text-[10px] font-normal leading-[150%]">{specialist}</div>
                        </div>
                    </div>
                )}
                <div className="text-[#06011e] text-xl font-bold leading-relaxed max-md:text-lg max-md:leading-[130%]">
                    {first_name + " " + last_name}
                </div>
                <div className="text-[#06011e] text-sm font-normal leading-[150%]">{specialization}</div>
            </div>
            <div className="flex flex-col gap-2">
                {years_experience && <ExperienceRow experience={years_experience} />}
                {education && <EducationRow education={education} />}
            </div>
            <MakeAnAppointmentLink onClick={() => onAppointmentClick(oneHealer)}>
                Записатись до лікаря
            </MakeAnAppointmentLink>
        </div>
    );
}